import { getLogger } from '@wk/elm-uui-common';
import { User, UserManager } from 'oidc-client';
import { UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import { config } from '../config';
import { ConnectivityService } from './connectivityService';
import { LoggingService } from './loggingService';
import { MessageService, MessageType } from './messageService';
import { OidcStorage } from './oidcStorage';
import { OidcStorageHelper } from './oidcStorageHelper';
import { OidcTokenClient } from './oidcTokenClient';
import { RouteManagementService } from './routeManagementService';
import { UrlHelper } from './urlHelper';

const SIGNIN_SILENT_LOCK_NAME = 'UserManager.signinSilent';
const TOKEN_RENEW_THRESHOLD_SECONDS = 60;
export const SSO_ID_QUERY_PARAMETER = 'sso_id';
export const NETWORK_ID_QUERY_PARAMETER = 'network_id';
export const TARGET_URL_QUERY_PARAMETER = 'target_url';
export const ONE_TIME_TOKEN_QUERY_PARAMETER = 'onetimetoken';
const INDIVIDUAL_ID_QUERY_PARAMETER = 'individual_id';
export const TEMP_LOGIN_SESSION_ID_QUERY_PARAMETER = 'temp_login_session_id';

const getLog = () => getLogger('t360.oidc');

// tslint:disable: no-invalid-this
// tslint:disable: no-any
function isNativeLocksSupported() {
    // tslint:disable-next-line: no-any
    return navigator && (navigator as any).locks && (navigator as any).locks.request;
}

async function canSkipTokenRefresh(manager: UserManager, args: any = {}) {
    // always perform token refresh if acr_values are set - meaning this is a network switch request
    if (args.acr_values) {
        return false;
    }

    const user = await manager.getUser();
    // if token renew happened recently
    if (user && user.expires_in <= TOKEN_RENEW_THRESHOLD_SECONDS) {
        return false;
    }

    return true;
}

function makeSigninSilentNativelyLocked() {
    const baseFunc = UserManager.prototype.signinSilent;
    UserManager.prototype.signinSilent = async function signinSilentLocked(args: any = {}) {
        if (await canSkipTokenRefresh(this, args)) {
            getLog().debug('Token renewed recently. Skipping.');
            return (await this.getUser())!;
        }

        const lockManager = (navigator as any).locks;
        return await lockManager.request(SIGNIN_SILENT_LOCK_NAME, async () => {
            getLog().debug('In native lock critical section.');

            if (await canSkipTokenRefresh(this, args)) {
                getLog().debug('Token renewed recently. Skipping.');
                return (await this.getUser())!;
            }

            const result = await baseFunc.bind(this)(args);
            getLog().debug('Leaving critical section');
            return result;
        });
    };
}
// tslint:enable: no-invalid-this
// tslint:enable: no-any

function buildUserManager(configuration: UserManagerSettings) {
    getLog().debug('Initializing UserManager.');

    getLog().debug('Deleting id_token_hint from previous sessions.');
    new OidcStorage().removeIdTokenHint();

    if (isNativeLocksSupported()) {
        getLog().debug('Browser supports native locks. Update singinSilent to use native locks.');
        makeSigninSilentNativelyLocked();
    } else {
        getLog().error('Browser does not support native locks. Possible issues on concurrent token refresh.');
    }

    LoggingService.setupOidcLogger();

    const manager = new UserManager(configuration);

    manager.events.addSilentRenewError(async (error: Error) => {
        getLog().error('Error: ', error);
        if (await ConnectivityService.handleOidcNetworkError(error)) {
            return;
        }

        // in case of any other error - logout
        await ConnectivityService.fireUnauthorizedEvent();
    });

    // tslint:disable-next-line: no-any
    (manager as any)._tokenClient = new OidcTokenClient(manager);
    return manager;
}

const userManagerConfig: UserManagerSettings = {
    client_id: config.get('REACT_APP_IDENTITY_CLIENT_ID'),
    response_type: 'code',
    scope: 'views openid IdentityServerApi ids-one-time-auth-token-create offline_access network-setting-validation urpservice legal-engagement-token-t360',
    authority: config.get('REACT_APP_IDENTITY_URL'),
    redirect_uri: `${config.get('REACT_APP_UI_URL')}/oidccallback`,
    automaticSilentRenew: true,
    accessTokenExpiringNotificationTime: Math.floor(Math.random() * 5) + 50,
    post_logout_redirect_uri: config.get('REACT_APP_UI_URL'),
    userStore: new WebStorageStateStore({ store: new OidcStorage() }),
    monitorSession: false,
    clockSkew: ConnectivityService.clientTimeDifference()
};
let userManager: UserManager;
export function getUserManager(): UserManager {
    if (!userManager) {
        userManager = buildUserManager(userManagerConfig);
    }
    return userManager;
}

export function logOutPrimary() {
    getLog().debug('Doing primary logout.');
    userManager.getUser().then(async (user) => {
        const oidcStorage = new OidcStorage();
        await oidcStorage.deleteToken();

        if (await RouteManagementService.isSupported()) {
            await RouteManagementService.clearDefaultUrl();
        }

        getLog().debug('Getting id_token_hint.');
        let idToken = user?.id_token || '';
        if (idToken) {
            getLog().debug(
                'Storing id_token_hint in shared storage so other instances could use it in case of logoutPrimary call',
            );
            oidcStorage.setIdTokenHint(idToken);
        } else {
            getLog().debug('No id_token_hint in userManager. Getting it from shared storage.');
            idToken = oidcStorage.getIdTokenHint() || '';
        }

        getLog().debug('Notifying other instances to do secondary logout.');
        MessageService.getInstance().notify({
            type: MessageType.Logout,
            message: { idTokenHint: idToken },
        });
        try {
            await userManager.signoutRedirect(getSignOutArgs({ idToken, user }));
        } catch (error) {
            getLog().error('Error: ', error);
            await ConnectivityService.handleOidcNetworkError(error);
        }
    });
}

export async function logOutSecondary(idToken: string) {
    getLog().debug('Doing secondary logout.');
    try {
        const user = await userManager.getUser();
        await userManager.signoutRedirect(getSignOutArgs({ idToken, skipLogout: true, user }));
    } catch (error) {
        getLog().error('Error: ', error);
        await ConnectivityService.handleOidcNetworkError(error);
    }
}

let ssoIdQueryParam: string | null;
let networkIdQueryParam: string | null;
let tempLoginSessionIdQueryParam: string | null;
let targetUrlQueryParam: string | null;
let oneTimeTokenQueryParam: string | null;
export function collectQueryParams(): void {
    ssoIdQueryParam = UrlHelper.getUrlParameterByName(SSO_ID_QUERY_PARAMETER);
    networkIdQueryParam = UrlHelper.getUrlParameterByName(NETWORK_ID_QUERY_PARAMETER);
    tempLoginSessionIdQueryParam = UrlHelper.getUrlParameterByName(TEMP_LOGIN_SESSION_ID_QUERY_PARAMETER);
    targetUrlQueryParam = UrlHelper.getUrlParameterByName(TARGET_URL_QUERY_PARAMETER);
    oneTimeTokenQueryParam = UrlHelper.getUrlParameterByName(ONE_TIME_TOKEN_QUERY_PARAMETER);
}

export function getSignInArgs(): SignInArgs {
    const acrValues: string[] = [];
    const ssoId = ssoIdQueryParam;
    const networkId = networkIdQueryParam;
    const targetUrl = targetUrlQueryParam;
    const tempLoginSessionId = tempLoginSessionIdQueryParam;
    const oneTimeToken = oneTimeTokenQueryParam;

    OidcStorageHelper.removeSsoId();

    if (ssoId?.length) {
        OidcStorageHelper.setSsoId(ssoId);
        acrValues.push(`integrated_login_id:${ssoId}`);
    }
    acrValues.push(OidcStorage.isHybridMode() ? `login_from_environment:hybrid` : `login_from_environment:oc`);
    if (networkId?.length) {
        acrValues.push(`network_id:${networkId}`);
    }
    if (tempLoginSessionId?.length) {
        acrValues.push(`temp_login_session_id:${tempLoginSessionId}`);
    }
    if (oneTimeToken?.length) {
        acrValues.push(`one_time_token:${oneTimeToken}`);
    }

    return {
        extraQueryParams: {
            deepLink: targetUrl ?? '',
        },
        acr_values: acrValues.length > 0 ? acrValues.join(' ') : undefined,
        prompt: oneTimeToken?.length ? 'login' : undefined,
    };
}

type SignOutOptions = { idToken: string; skipLogout?: boolean; user: User | null };
export function getSignOutArgs({ idToken, skipLogout = false, user }: SignOutOptions): SignOutArgs {
    const ssoId = OidcStorageHelper.getSsoId();
    const networkId = user?.profile.network_id as string | undefined;
    const individualId = user?.profile.sub;
    const returnUrlQueryParams = [
        [SSO_ID_QUERY_PARAMETER, ssoId],
        [NETWORK_ID_QUERY_PARAMETER, networkId],
        [INDIVIDUAL_ID_QUERY_PARAMETER, individualId],
    ].reduce<string[]>((queryParams, [key, value]) => {
        if (key && value) {
            queryParams.push(`${key}=${value}`);
        }
        return queryParams;
    }, []);
    return {
        id_token_hint: idToken,
        extraQueryParams: {
            return_url_query_parameters: returnUrlQueryParams.join('&') || undefined,
            skip_logout: skipLogout,
        },
    };
}

export interface SignInArgs {
    login_hint?: string;
    acr_values?: string;
    extraQueryParams?: { deepLink: string | null };
    prompt?: string;
}

interface SignOutArgs {
    id_token_hint?: string;
    extraQueryParams?: {
        return_url_query_parameters?: string;
        skip_logout?: boolean;
    };
}
