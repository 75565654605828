import { IQueueServiceInterface } from '@wk/elm-uui-context-handler';
import { LOGGING_OUT } from '../components/app/appContainer/useMessageBus.hook';
import { getAuthenticationProvider } from '../components/authentication/AuthenticationProviderService';
import { clGetQueue } from '../components/contextLayerService/contextLayerService';
import { useAppSelector } from '../store';
import { getAppResources } from '../store/slices';
import { clearLocationDataForOC } from '../utils/ocLocationService';

export const useLogOut = () => {
    const appResources = useAppSelector(getAppResources);
    const logOut = () => {
        clGetQueue().then((queueManager: IQueueServiceInterface) => {
            queueManager?.setQueuePaused();
            clearLocationDataForOC();
            sessionStorage.setItem(LOGGING_OUT, 'true');
            const authProvider = getAuthenticationProvider();
            authProvider.signOut(appResources.logoutUrl, true);
        });
    };
    return logOut;
};
